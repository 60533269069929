<template>
  <v-main>
    <slot/>
  </v-main>
</template>

<script>
  export default {
    name: "EmptyLayout"
  }
</script>

<style scoped>

</style>
