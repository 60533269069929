<template>
  <div class="home">
    <img alt="Vue logo" :src="logo">

  </div>
</template>

<script>

import logo from "../assets/logo.png"
// @ is an alias to /src

export default {
  name: 'HomeView',
  data: () => ({
    logo
  }),
}
</script>
