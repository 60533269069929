import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from './store'

import DefaultLayout from "@/layouts/DefaultLayout";
import EmptyLayout from "@/layouts/EmptyLayout";

import VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"

loadFonts().then();

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .use(VNetworkGraph)
  .component('default-layout', DefaultLayout)
  .component('empty-layout', EmptyLayout)
  .mount('#app');

