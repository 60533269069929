<template>
  <v-app-bar color="blue-darken-4" dense dark app>
    <v-app-bar-nav-icon/>
    <v-app-bar-title>Network Modeler</v-app-bar-title>
    <v-spacer/>
    <router-link to="/">
      <v-btn elevation="2" outlined rounded>Home</v-btn>
    </router-link>
    <router-link to="/about">
      <v-btn class="ma-6" elevation="2" outlined rounded>About</v-btn>
    </router-link>
    <router-link to="/modeler">
      <v-btn class="ma-6" elevation="2" outlined rounded>Modeler</v-btn>
    </router-link>
    <v-btn class="auth text-capitalize" v-if="!AuthState.isAuthenticated" @click="login()">
      Sign In
    </v-btn>
    <v-btn class="auth text-capitalize" v-else @click="logout()">
      Sign Out
    </v-btn>
  </v-app-bar>
  <v-navigation-drawer color="grey-lighten-2" app rail="" expand-on-hover="">
    <!-- -->
  </v-navigation-drawer>
  <v-main>
    <v-container fluid>
      <slot/>
    </v-container>
  </v-main>
  <v-footer app>
    <!-- -->
  </v-footer>
</template>

<script setup>
  import { useAuth0, AuthState } from "../auth/index";
  const { login, logout, initAuth } = useAuth0(AuthState);

  initAuth();

</script>

<style scoped>
  .auth {
    letter-spacing: normal;
  }

</style>
