<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>

  const defaultLayout = "default";

  export default {
    name: 'App',

    computed: {
      layout() {
        return (this.$route.meta.layout || defaultLayout) + "-layout"
      }
    }
  };
</script>

<style lang="scss">

  html { overflow-y: auto !important;}

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
