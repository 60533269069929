import { createStore } from 'vuex'
import { VuexPersistence } from "vuex-persist";
import { AuthState } from "../auth/index";

const backendHost = process.env.VUE_APP_BACKEND;


const vuexLocalStorage = new VuexPersistence({
  storage: window.localStorage
});

export default createStore({
  state () {
    return {
      sandbox: "",

      plannedNodes: {},
      plannedEdges: {},
      plannedLayouts: {},

      deployedNodes: {},
      deployedEdges: {}
    }
  },
  getters: {
    // plannedRouters ( state ) {
    //   state.plannedNodes.filter(node => node.type === "router")
    // },
    // plannedSwitches ( state ) {
    //   state.plannedNodes.filter(node => node.type === "switch")
    // },
    // plannedHosts ( state ) {
    //   state.plannedNodes.filter(node => node.type === "host")
    // },
    // deployedRouters ( state ) {
    //   state.deployedNodes.filter(node => node.type === "router")
    // },
    // deployedSwitches ( state ) {
    //   state.deployedNodes.filter(node => node.type === "switch")
    // },
    // deployedHosts ( state ) {
    //   state.deployedNodes.filter(node => node.type === "host")
    // }
  },
  mutations: {
    setSandbox (state, sandbox) {
      state.sandbox = sandbox;
    },

    updatePlannedNodes (state, nodes) {
      state.plannedNodes = nodes;
    },
    updatePlannedEdges (state, edges) {
      state.plannedEdges = edges;
    },
    updatePlannedLayouts (state, layouts) {
      state.plannedLayouts = layouts
    },

    deploy (state) {
      state.deployedNodes = state.plannedNodes;
      state.deployedEdges = state.plannedEdges;
    },

    destroy (state) {
      state.deployedNodes = null;
      state.deployedEdges = null;
    }
  },
  actions: {
    async claim (context) {
      const accessToken = await AuthState.auth0.getTokenSilently();
      const response = await fetch(
        `https://${backendHost}/sandbox/claim`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          }
        });
      if (response.status === 200) {
        const sandbox = await response.json();
        context.commit("setSandbox", sandbox.uid)
      }
    },

    async release (context) {
      const accessToken = await AuthState.auth0.getTokenSilently();
      const response = await fetch(
        `https://${backendHost}/sandbox/release/${context.state.sandbox}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          }
        });
      if (response.status === 200) {
        context.commit("setSandbox", null)
      }
    },

    async deploy (context) {
      const data = {};
      data.devices = [];
      for (const node in context.state.plannedNodes) {
        data.devices.push(context.state.plannedNodes[node])
      }
      data.links = [];
      for (const edge in context.state.plannedEdges) {
        const edge_data = context.state.plannedEdges[edge];
        data.links.push({
          source: context.state.plannedNodes[edge_data.source].name,
          destination: context.state.plannedNodes[edge_data.target].name
        })
      }
      console.log(JSON.stringify(data));
      const response = await fetch(
        `https://${backendHost}/sandbox/${context.state.sandbox}/create`,
        {
          method: "POST",
          body: JSON.stringify(data)
        });
      if (response.status === 200) {
        context.commit('deploy')
      }
    },

    async destroy (context) {
      const response = await fetch(
        `https://${backendHost}/sandbox/${context.state.sandbox}/destroy`,
        {
          method: "DELETE",
        });
      if (response.status === 200) {
        context.commit('destroy')
      }
    }
  },
  modules: {
  },
  // Seems to break devtool Vuex -> Stuck on initial values
  // https://github.com/championswimmer/vuex-persist/issues/233
  plugins: [vuexLocalStorage.plugin]
})
