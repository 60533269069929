<template>
  <div id="terminal"></div>
</template>

<script>
  import "xterm/css/xterm.css"
  import { Terminal } from "xterm"
  import { FitAddon } from "xterm-addon-fit"
  import { AttachAddon } from "xterm-addon-attach"

  export default {
    props: {
      host: {
        type: String,
        required: true
      }
    },
    name: "TerminalSSH",
    created() {
      window.addEventListener("resize", this.resize);
    },
    unmounted() {
      window.removeEventListener("resize", this.resize);
    },
    data() {
      return {
        term: null,
        fitAddon: null,
        socket: null
      }
    },
    mounted() {
      let term = new Terminal();
      this.term = term;
      // const socket = new WebSocket('ws://localhost:8000/ssh/');
      let socket = new WebSocket(`wss://${process.env.VUE_APP_BACKEND}/sandbox/${this.$store.state.sandbox}/ssh?host=${this.host}`);
      this.socket = socket;
      this.fitAddon = new FitAddon();
      const attachAddon = new AttachAddon(this.socket);

      this.term.loadAddon(this.fitAddon);
      // Attach the socket to term
      this.term.loadAddon(attachAddon);
      this.term.open(document.getElementById("terminal"));
      this.fitAddon.fit();
      this.socket.addEventListener('open', function () {
        socket.send(JSON.stringify({"resize": {"width": term.cols, "height": term.rows}}));
      });


    },
    methods: {
      resize: function() {
        this.fitAddon.fit();
        this.socket.send(JSON.stringify({"resize": {"width": this.term.cols, "height": this.term.rows}}));
      }
    }
  }
</script>

<style scoped>
  html, body {
    overflow-y: hidden;
  }
  #terminal {
    height: 100vh;
    width: 100vw;
    display: flex;
    /*border: 7px solid black;*/
    overflow-y: hidden;
    background-color: black;
  }

</style>
