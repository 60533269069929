<template>
  <TerminalSSH :host="host"/>
</template>

<script>
  import TerminalSSH from "@/components/TerminalSSH";

  export default {
    props: {
      host: {
        type: String,
        required: true
      }
    },
    name: "TerminalView",
    components: {
      TerminalSSH
    },
    created() {
      document.title = `SSH - ${this.host}`
    },
  }
</script>

<style scoped>

</style>
